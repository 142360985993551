<template>
  <b-modal id="send-funds" hide-footer title="Send Funds">
    <div>Specify the amount to send to {{ buyer["name"] }}</div>
    <b-form @submit.prevent="credit">
      <b-form-group label="Enter Amount">
        <b-input-group size="sm" prepend="UGX">
          <b-form-input v-model="amount" type="number" required></b-form-input>
        </b-input-group>
      </b-form-group>
      <div>
          <div class="font-weight-bold">New Balance</div>
          <div>UGX {{new_balance}}</div>
      </div>
      <hr/>
      <div>
        <b-button variant="outline-primary">Cancel</b-button>
        <b-button variant="primary" class="float-right" type="submit">Continue</b-button>
      </div>
    </b-form>
  </b-modal>
</template>
<script>
import * as api from "../../api"
export default {
  computed: {
    /**
     * @returns {import("../../../../api").Buyer}
     */
    buyer() {
      return this.$store.getters["buyera/getProfile"];
    },
    new_balance(){
        return Number(this.buyer.wallet["balance"])+Number(this.amount);
    }
  },
  data() {
    return {
      amount: 0,
    };
  },
  methods: {
    credit() {
        api.credit(this.buyer.id,this.amount).then((wallet)=>{
            this.$store.dispatch("buyera/updateWallet",wallet).then(()=>{
                this.amount = 0;
            })
        })
    },
  },
};
</script>