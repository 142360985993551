var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('b-navbar',[_c('b-navbar-brand',[_c('b-link',{staticClass:"text-dark",attrs:{"to":"/suppliers/buyers"}},[_vm._v("BUYING AGENTS ")]),_c('b-icon-chevron-right'),_c('div',{staticClass:"d-inline-block",class:{'buyer-loading':_vm.buyerLoading}},[_vm._v(_vm._s(_vm.buyer.name))])],1),_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"size":"sm","variant":"outline-danger"}},[_c('b-icon-trash'),_vm._v(" Delete Agent ")],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"pl-4"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{attrs:{"src":"","size":"3rem"}}),_vm._v("  "),_c('div',{class:{'buyer-loading':_vm.buyerLoading}},[_vm._v(_vm._s(_vm.buyer.name))])],1)]),_c('b-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"sidebar-width text-right"},[_c('button',{staticClass:"btn-opt primary"},[_c('b-icon-arrow-counterclockwise'),_vm._v(" Refresh ")],1),_c('button',{staticClass:"btn-opt primary"},[_c('b-icon-printer'),_vm._v(" Print ")],1),_c('button',{staticClass:"btn-opt primary"},[_c('b-icon-book'),_vm._v(" Export ")],1)])])],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"9"}},[_c('b-nav',{staticClass:"mb-2",attrs:{"pills":""}},[_c('b-nav-item',{attrs:{"active":_vm.$route.name == 'buyer-home',"link-classes":{ 'text-dark': !(_vm.$route.name === 'buyer-home') },"to":{
              name: 'buyer-home',
              params: { buyer_id: _vm.$route.params.buyer_id },
            }}},[_vm._v("Home")]),_c('b-nav-item',{attrs:{"active":_vm.$route.name == 'buyer-agents',"link-classes":{
              'text-dark': !(_vm.$route.name == 'buyer-agents'),
            },"to":{
              name: 'buyer-agents',
              params: { buyer_id: _vm.$route.params.buyer_id },
            }}},[_vm._v("Agents")]),_c('b-nav-item',{attrs:{"active":_vm.$route.name == 'buyer-history',"link-classes":{
              'text-dark': !(_vm.$route.name == 'buyer-history'),
            },"to":{
              name: 'buyer-history',
              params: { buyer_id: _vm.$route.params.buyer_id },
            }}},[_vm._v("Transaction History")]),_c('b-nav-item',{attrs:{"active":_vm.$route.name == 'buyer-info',"link-classes":{ 'text-dark': !(_vm.$route.name == 'buyer-info') },"to":{
              name: 'buyer-info',
              params: { buyer_id: _vm.$route.params.buyer_id },
            }}},[_vm._v("Agent Info")])],1),_c('hr'),_c('router-view')],1),_c('b-col',{staticClass:"border-left",attrs:{"cols":"3"}},[_c('agent-credit')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }