<template>
  <b-modal id="record-funds" hide-footer title="Record Funds">
    <div class="my-2">Record Cash Advance {{ buyer["name"] }}</div>
    <b-form @submit.prevent="credit">
      <b-form-group label="Enter Amount">
        <b-input-group size="md" prepend="UGX">
          <b-form-input
            v-model.number="amount"
            required
            type="number"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
      <div class="my-2">
        <div class="font-weight-bold">New Balance</div>
        <div>UGX {{ new_balance }}</div>
      </div>
      <b-container v-show="loading">
        <b-row class="d-flex justify-content-center mb-3">
          <span class="font-weight-light text-muted"
            >Processing...&nbsp;&nbsp;</span
          >
          <b-spinner variant="secondary" label="Loading..."></b-spinner>
        </b-row>
      </b-container>
      <div class="w-100">
        <b-button variant="outline-primary" type="button" @click="close"
          >Cancel</b-button
        >
        <b-button
          :disabled="loading"
          variant="primary"
          type="submit"
          class="float-right"
          >Record Funds</b-button
        >
      </div>
    </b-form>
  </b-modal>
</template>
<script>
import * as api from "../../api";
export default {
  computed: {
    /**
     * @returns {import("../../../../api").Buyer}
     */
    buyer() {
      return this.$store.getters["buyera/getProfile"];
    },
    new_balance() {
      return this.buyer.wallet["balance"] + this.amount;
    },
  },
  data() {
    return {
      amount: 0,
      loading: false,
    };
  },
  methods: {
    credit() {
      this.loading = true;
      api
        .credit(this.buyer.id, this.amount)
        .then((wallet) => {
          this.$store.dispatch("buyera/updateWallet", wallet).then(() => {
            this.amount = 0;
            this.loading = false;
            this.close();
          });
          this.loading = false;
          this.$bvToast.toast("Successful", {
            title: "Successfully recorded",
            variant: "success",
            solid: true,
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$bvToast.toast("Failed", {
            title: "Failed to Record funds",
            variant: "danger",
            solid: true,
          });
          this.$bvToast.toast(error.message, {
            title: "Failed to add funds",
            variant: "danger",
            solid: true,
          });
        });
    },
    close() {
      this.$bvModal.hide("record-funds");
    },
  },
};
</script>
