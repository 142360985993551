<template>
  <div class="container-fluid">
    <b-navbar>
      <b-navbar-brand>
        <b-link class="text-dark" to="/suppliers/buyers">BUYING AGENTS </b-link>
        <b-icon-chevron-right></b-icon-chevron-right>
        <div class="d-inline-block" :class="{'buyer-loading':buyerLoading}">{{buyer.name}}</div>
      </b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <b-button v-show="false" size="sm" variant="outline-danger">
          <b-icon-trash></b-icon-trash>
          Delete Agent
        </b-button>
      </b-navbar-nav>
    </b-navbar>
    <b-row>
      <b-col class="pl-4">
        <div class="d-flex align-items-center">
          <b-avatar src="" size="3rem"></b-avatar>&nbsp;
          <div :class="{'buyer-loading':buyerLoading}">{{buyer.name}}</div>
        </div>
      </b-col>
      <b-col cols="auto">
        <div class="sidebar-width text-right">
          <button class="btn-opt primary">
            <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
            Refresh
          </button>
          <button class="btn-opt primary">
            <b-icon-printer></b-icon-printer> Print
          </button>
          <button class="btn-opt primary">
            <b-icon-book></b-icon-book> Export
          </button>
        </div>
      </b-col>
    </b-row>
    <hr />

    <b-row>
      <b-col cols="9">
          <b-nav pills class="mb-2">
            <b-nav-item
              :active="$route.name == 'buyer-home'"
              :link-classes="{ 'text-dark': !($route.name === 'buyer-home') }"
              :to="{
                name: 'buyer-home',
                params: { buyer_id: $route.params.buyer_id },
              }"
              >Home</b-nav-item
            >
            <b-nav-item
              :active="$route.name == 'buyer-agents'"
              :link-classes="{
                'text-dark': !($route.name == 'buyer-agents'),
              }"
              :to="{
                name: 'buyer-agents',
                params: { buyer_id: $route.params.buyer_id },
              }"
              >Agents</b-nav-item
            >
            <b-nav-item
              :active="$route.name == 'buyer-history'"
              :link-classes="{
                'text-dark': !($route.name == 'buyer-history'),
              }"
              :to="{
                name: 'buyer-history',
                params: { buyer_id: $route.params.buyer_id },
              }"
              >Transaction History</b-nav-item
            >
            <b-nav-item
              :active="$route.name == 'buyer-info'"
              :link-classes="{ 'text-dark': !($route.name == 'buyer-info') }"
              :to="{
                name: 'buyer-info',
                params: { buyer_id: $route.params.buyer_id },
              }"
              >Agent Info</b-nav-item
            >
            <!-- <b-nav-item :active="$route.name=='sales'" :link-classes="{'text-dark':!($route.name=='sales')}" to="/reports/sales">Sales</b-nav-item> -->
          </b-nav>
          <hr />
          <router-view ></router-view>

      </b-col>
      <b-col cols="3" class="border-left">
        <agent-credit>
        </agent-credit>
     </b-col>
    </b-row>
  </div>
</template>
<style scoped>
@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
.buyer-loading{
  width:18ch;
  border-radius: 2px;
  height: 1.5rem;
  animation: skeleton-loading 1s linear infinite alternate;
}
</style>
<script>
import AgentCredit from "./components/agent_credit/AgentCredit.vue"
import * as api from "./api";
import { bus } from '../../../../../../main';

export default {
  components:{AgentCredit},
  data(){
    return {
      buyerLoading:false,
    }
  },
  computed:{
    buyer(){
      return this.$store.getters["buyera/getProfile"];
    }
  },
  mounted(){
    this.loadProfile();
  },
  methods:{
    loadProfile(){
      const buyer_id = this.$route.params["buyer_id"];
      this.buyerLoading = true;
      api.find_one(buyer_id).then(data=>{
        this.$store.dispatch("buyera/saveProfile",data);
        bus.$emit("buyer-loaded");
        this.buyerLoading = false;
      })
    }
  }
};
</script>
