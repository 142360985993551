<template>
  <div>
    <div class="shadow p-3 mb-5 bg-white rounded">
      <b-row>
        <b-col>
          <h6>Balance</h6>
          <p>
            <b>
              UGX <span>{{ buyer["wallet"]["balance"] }}</span>
            </b>
          </p>
          <div>
            <b-link
              class="no-decorate text-success"
              :to="{
                name: 'buyer-history',
                params: { buyer_id: $route.params.buyer_id },
              }"
            >
              <u><small>Transaction History</small></u>
            </b-link>
          </div>
        </b-col>
      </b-row>
      <div class="pt-3 mb-4">
        <b-dropdown variant="outline-success" class="float-left" size="sm">
            <template #button-content>
              <b-icon-plus></b-icon-plus>
              <small><b>Add Funds</b></small> 
            </template>
            <b-dropdown-item v-if="false" variant="success" v-b-modal.send-funds>Send Money</b-dropdown-item>
            <b-dropdown-item v-b-modal.record-funds> Record Funds</b-dropdown-item>
        </b-dropdown>
        <b-button class="float-right" size="sm" variant="outline-danger" v-b-modal.remove-funds>
          <small><b>Remove Funds -</b></small>
        </b-button>
      </div>
    </div>
    <div class="shadow-none p-3 mb-5 bg-light rounded">
      <p>Total Commission (UGX 50 per Kg)</p>
      <p class="text-success">
        <b> UGX 0 </b>
      </p>
    </div>
    <record-funds></record-funds>
    <send-funds></send-funds>
    <remove-funds></remove-funds>
  </div>
</template>
<script>
import RecordFunds from "../add_funds/RecordFunds.vue"
import RemoveFunds from "../add_funds/RemoveFunds.vue"
import SendFunds from "../add_funds/SendFunds.vue"
export default {
  components: {RecordFunds,SendFunds,RemoveFunds },
  computed:{
    /**
     * @returns {import("../../../../api").Buyer}
     */
    buyer(){
      return this.$store.getters["buyera/getProfile"];
    }
  }
};
</script>
